<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Name Input -->
          <b-col cols="6">
            <b-form-group id="input-name">
              <label for="nameInput">Name: <span class="text-danger">*</span></label>
              <b-form-input
                id="nameInput"
                name="name"
                v-model="editedItem.title"
                type="text"
                aria-describedby="input-name-feedback"
                placeholder="Article name..."
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true, min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- Input Priority -->
          <b-col cols="3">
            <b-form-group id="input-name">
              <label for="priorityInput">Priority:</label>
              <b-form-input
                id="priorityInput"
                name="priority"
                v-model="editedItem.priority"
                type="number"
                placeholder="Priority"
                data-vv-as="Priority"
                :class="{ input: true, 'is-danger': errors.has('priority') }"
                v-validate="{ required: true, min: 1, max: 256, numeric: true }"
              ></b-form-input>
              <span v-show="errors.has('priority')" class="is-danger-text position-relative">{{
                errors.first('priority')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- select category -->
          <b-col cols="3">
            <b-form-group id="input-category" label="Category:" label-for="categoryInput">
              <b-form-select
                v-model="selectedCategory"
                :options="categoryAdsList"
                id="categoryInput"
                name="Category"
                data-vv-as="Category"
                :class="{ input: true, 'is-danger': errors.has('Category') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Category--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select category--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('Category')" class="is-danger-text position-relative">{{
                errors.first('Category')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- input Image Url -->
          <b-col cols="6">
            <b-form-group id="input-img-url" label="Image URL:" label-for="img-url" aria-describedby="input-image-help">
              <b-form-input
                id="img-url"
                name="imageUrl"
                v-model="editedItem.image_url"
                type="text"
                placeholder="Image URL..."
                data-vv-as="Image URL"
                :class="{ input: true, 'is-danger': errors.has('imageUrl') }"
                v-validate="{ min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('imageUrl')" class="is-danger-text position-relative">{{
                errors.first('imageUrl')
              }}</span>
              <b-form-text id="input-image-help">Enter exist image link or upload new image file.</b-form-text>
            </b-form-group>
          </b-col>
          <!-- choose Image file -->
          <b-col cols="6">
            <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
              <b-form-file
                v-model="editedItem.image_file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>

          <!-- Short Description -->
          <b-col cols="6">
            <b-form-group id="input-short-description" label="Short Description:" label-for="short-description">
              <b-form-input
                id="short-description"
                name="shortDescription"
                v-model="editedItem.short_description"
                type="text"
                placeholder="Short Description..."
                data-vv-as="Short Description"
                :class="{
                  input: true,
                  'is-danger': errors.has('shortDescription')
                }"
                v-validate="{ min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('shortDescription')" class="is-danger-text position-relative">{{
                errors.first('shortDescription')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- status -->
          <b-col cols="6">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select v-model="filter_status" :options="statusList" name="Status">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>
          <!-- Tag -->
          <b-col cols="6">
            <b-form-group id="input-tag">
              <label for="tag">Tag:</label>
              <b-form-input
                id="tag"
                name="Tag"
                v-model="editedItem.tag"
                type="text"
                aria-describedby="input-tag-feedback"
                placeholder="Tag..."
                data-vv-as="Tag"
                :class="{
                  input: true,
                  'is-danger': errors.has('Tag')
                }"
                v-validate="{ required: true, min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('Tag')" class="is-danger-text position-relative">{{ errors.first('Tag') }}</span>
            </b-form-group>
          </b-col>
          <!-- Arthor -->
          <b-col cols="6">
            <b-form-group id="input-author">
              <label for="author">Aurthor:</label>
              <b-form-input
                id="author"
                name="Author"
                v-model="editedItem.author"
                type="text"
                aria-describedby="input-author-feedback"
                placeholder="Author..."
                data-vv-as="Author"
                :class="{
                  input: true,
                  'is-danger': errors.has('Author')
                }"
                v-validate="{ min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('Author')" class="is-danger-text position-relative">{{
                errors.first('Author')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- Content -->
          <b-col v-if="dialog" cols="12" class="mb-4">
            <label for="ck-content">Content:</label>
            <ckeditor id="ck-content-1" trim v-model="editedItem.content" :config="editorConfig"></ckeditor>
          </b-col>
          <!-- Content2 -->
          <b-col v-if="dialog" cols="12" class="mb-4">
            <label for="ck-content2">Content2:</label>
            <ckeditor id="ck-content-2" trim v-model="editedItem.content2" :config="editorConfig"></ckeditor>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Article List</h4>
      </b-col>
      <!-- FilterName -->
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Article" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search articles ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- FilterCategori -->
      <b-col cols="2">
        <b-form-group id="input-screen-filter" label="Category" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_category" :options="categoryAdsList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Filter Active -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(image_preview)="item">
        <!-- {{ item.value }} -->
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import LightBox from 'vue-image-lightbox';
import _ from 'lodash';
import axios from 'axios';
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: 'Article List',
  components: {
    LightBox
  },
  data() {
    return {
      search: '',
      isBusy: false,
      filter_name: '',
      filter_category: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Title', key: 'title' },
        { text: 'Category', key: 'category' },
        { text: 'Image Url', key: 'image_preview' },
        { text: 'Short Description', key: 'short_description' },
        { text: 'Tag', key: 'tag' },
        { text: 'Author', key: 'author' },
        { text: 'Priority', key: 'priority' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      // screenPosList: [],
      categoryAdsList: [],
      selectedCategory: null,
      modalTitle: '',
      // loading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        title: '',
        category_id: '',
        category: '',
        image_url: '',
        short_description: '',
        content: '',
        content2: '',
        tag: '',
        author: '',
        status: '',
        priority: '',
        image_file: null
      },
      dialog: false,
      editedIndex: -1,
      // editorUrl: "https://cdn.ckeditor.com/4.14.1/full-all/ckeditor.js",
      editorData: '',
      editorConfig: {
        allowedContent: true,
        toolbarGroups: [
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
          { name: 'clipboard', groups: ['clipboard', 'undo'] },
          {
            name: 'editing',
            groups: ['find', 'selection', 'spellchecker', 'editing']
          },
          { name: 'forms', groups: ['forms'] },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
          },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          '/',
          { name: 'styles', groups: ['styles'] },
          { name: 'colors', groups: ['colors'] },
          { name: 'tools', groups: ['tools'] },
          { name: 'others', groups: ['others'] },
          { name: 'about', groups: ['about'] }
        ],
        removeButtons:
          'NewPage,ExportPdf,Print,Templates,CopyFormatting,RemoveFormat,BidiLtr,BidiRtl,Language,ShowBlocks,About,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Flash,HorizontalRule,SpecialChar,PageBreak,Iframe,Link,Unlink,Anchor,Form,Checkbox,Replace,Find,Scayt,SelectAll,CreateDiv,Blockquote'
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('name') ||
        !this.editedItem.title ||
        this.errors.has('Category') ||
        !this.selectedCategory ||
        this.errors.has('tag') ||
        !this.editedItem.tag ||
        this.errors.has('priority') ||
        !this.editedItem.priority ||
        !this.editedItem.content
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Articles List'
      }
    ]);
  },
  methods: {
    onFilterName: _.debounce(function() {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    fetchData() {
      this.loading = true;
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsArticle = ApiService.get(
        'articles',
        `?title=${this.filter_name}&category_id=${this.filter_category || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      const articleCategories = ApiService.get('article-categories');
      axios
        .all([adsArticle, articleCategories])
        .then(
          axios.spread((...response) => {
            const adsArticleRes = response[0];
            const categoriesRes = response[1];
            this.items = adsArticleRes.data.data.articleList.map((ads) => {
              return {
                ...ads,
                image_preview: [{ thumb: ads.image_url, src: ads.image_url }]
              };
            });
            this.rows = adsArticleRes.data.data.total;
            this.categoryAdsList = categoriesRes.data.data.categoriesList.map((item) => {
              return { text: item.name, value: item.id };
            });
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function() {
      this.dialog = true;
      this.modalTitle = 'Add Article';
    },
    editItem: function(item) {
      this.dialog = true;
      this.modalTitle = 'Update Article';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedCategory = item.category_id;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`articles/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Article deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        title: '',
        category_id: '',
        category: '',
        image_url: '',
        short_description: '',
        content: '',
        content2: '',
        tag: '',
        author: '',
        status: '',
        priority: '',
        image_file: null
      };
      this.editedIndex = -1;
      this.selectedCategory = null;

      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Validate required fields
      // Get new brand/cate/vendor name for data-table
      this.editedItem.category_id = this.selectedCategory;
      this.editedItem.status = this.filter_status;
      let formData = new FormData();
      for (const key in this.editedItem) {
        if (this.editedItem.hasOwnProperty(key)) {
          const element = this.editedItem[key];
          if (element !== '' && element !== null) {
            formData.append(key, element);
          }
        }
      }

      if (this.editedItem.image_url === '') {
        formData.append('image_url', '');
      }

      console.log(formData);
      // if (this.editedItem.short_description === "") {
      //   formData.append("short_description", "");
      // }
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      if (this.editedIndex > -1) {
        // Update article
        axios({
          method: 'PUT',
          url: `articles/${this.items[this.editedIndex].id}`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.status === 204) {
              //update game
              this.categoryAdsList.some((category) => {
                if (category.value == this.selectedCategory) {
                  this.editedItem.category = category.text;
                  this.editedItem.category_id = category.value;
                  return true;
                }
              });

              this.editedItem.status = this.filter_status;
              this.editedItem.image_preview = [
                {
                  thumb: this.editedItem.image_url,
                  src: this.editedItem.image_url
                }
              ];
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Article updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        axios({
          method: 'POST',
          url: 'articles',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data,
                image_preview: [
                  {
                    thumb: response.data.data.image_url,
                    src: response.data.data.image_url
                  }
                ]
              });
              this.close();
              this.$bvToast.toast('Article added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>
